import { fontSize, fontWeight, lineHeight } from 'constants/typography'
import mediaQueries from 'util/mediaqueries'
import { NeutralColors } from 'constants/colors'

import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

export const SIZES = [1, 2]
export const DEFAULT_SIZE = 1

const textSize1CSS = css`
  font-size: ${fontSize.small};
  line-height: ${lineHeight.large};

  ${mediaQueries.lg`
    font-weight: ${fontWeight.regular};
    font-size: ${fontSize.medium};
    line-height: ${lineHeight.medium};
  `}
`

const textSize2CSS = css`
  font-weight: ${fontWeight.regular};
  font-size: ${fontSize.medium};
  line-height: ${lineHeight.medium};

  ${mediaQueries.sm`
    font-size: ${fontSize.large};
    line-height: ${lineHeight.small};
  `}
`

export const Text = styled.p`
  color: ${({ inverted }) =>
    inverted ? NeutralColors.White : NeutralColors.Ink};
  font-style: normal;
  font-weight: normal;

  ${props => {
    switch (props.size) {
      case 2:
        return textSize2CSS
      default:
      case 1:
        return textSize1CSS
    }
  }}
`

Text.propTypes = {
  children: PropTypes.node.isRequired,
  as: PropTypes.string,
  size: PropTypes.oneOf(SIZES),
  inverted: PropTypes.bool,
}

Text.defaultProps = {
  as: undefined,
  size: DEFAULT_SIZE,
  inverted: false,
}
